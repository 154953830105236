export type QuizContextType = {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  quizData: QuizData;
  setQuizData: React.Dispatch<React.SetStateAction<QuizData>>;
};

export type QuizData = {
  relationship: Relationship | null;
  mainGoal: MainGoal | null;
  gender: Gender | null;
  birthDate: {
    month: string | number;
    day: string | number;
    year: string | number;
  };
  birthTime: {
    hour: string | number;
    minute: string | number;
    timeFormat: TimeFormatType;
  };
  zodiac: string | null;
  decisions: DecisionsType;
  email: string;
  isAgreed: boolean;
  selectedPlan: string;
};

export type TimeFormatType = 'AM' | 'PM';
export type DecisionsType = 'Heart' | 'Head' | 'Both' | null;

export const DEFAUL_QUIZ_DATA: QuizData = {
  relationship: null,
  mainGoal: null,
  gender: null,
  birthDate: {
    month: '',
    day: '',
    year: '',
  },
  birthTime: {
    hour: '',
    minute: '',
    timeFormat: 'AM',
  },
  zodiac: null,
  decisions: null,
  email: '',
  isAgreed: false,
  selectedPlan: '1-Month plan',
};

export enum Relationship {
  SINGLE = 'SINGLE',
  IN_RELATIONSHIP = 'IN_RELATIONSHIP',
}

export enum MainGoal {
  FIND_PARTNER = 'FIND_PARTNER',
  UNDERSTAND_MYSELF = 'UNDERSTAND_MYSELF',
  BOTH = 'BOTH',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NON_BINARY = 'NON_BINARY',
}
