import React, { useMemo } from 'react';
import { Box, Link, Typography } from '@mui/material';

import mob_intro_bottom from '../../../assets/images/quiz/mob_intro_bottom.webp';
import { Routes } from '../../../App';
import { APP_CONSTANTS, PLANS } from '../../../utils/constants';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import { endTrial } from '../../../utils/common';

function Footer() {
  const { quizData } = useQuizState();
  const selectedPlan = useMemo(() => {
    return PLANS.find((plan) => plan.name === quizData.selectedPlan);
  }, [quizData.selectedPlan]);
  const trialEnd = endTrial(selectedPlan?.trialUnit || '', selectedPlan?.trialLenght || 0);
  const montlyPrice = useMemo(() => {
    if (selectedPlan) return (selectedPlan?.fullPrice / selectedPlan?.intervalLength).toFixed(2);
  }, [selectedPlan]);

  return (
    <Box
      sx={{
        backgroundColor: '#01091C',
        backgroundImage: `url(${mob_intro_bottom})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom',
      }}
    >
      <Box
        sx={{
          maxWidth: '460px',
          margin: 'auto',
          padding: '0 20px 200px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '32px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
              flexWrap: 'wrap',
              '& .link': {
                fontFamily: 'Sans400',
                fontSize: '16px',
                color: '#fff',
                textDecoration: 'underline',
                transition: 'all 0.3s ease',
                whiteSpace: 'nowrap',
                '&:hover': {
                  opacity: 0.8,
                },
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Sans400',
                fontSize: '16px',
                color: '#717889',
                textAlign: 'center',
                margin: '8px 0',
              }}
            >
              *Your {selectedPlan?.trialPeriod} will last until ({trialEnd}). You can cancel anytime before then and
              will not be charged the full program amount.
              <br />
              <br /> No question asked, no small print. If you decide {APP_CONSTANTS.APP_NAME} is good for you, on (
              {trialEnd}) you will be charged one payment for ${selectedPlan?.fullPrice} for your {selectedPlan?.name}{' '}
              (${montlyPrice}/month).
              <br />
              <br />
              {APP_CONSTANTS.APP_NAME} will automatically charge your card ${selectedPlan?.fullPrice} every{' '}
              {selectedPlan?.intervalLength} {selectedPlan?.intervalUnit}
              {selectedPlan && selectedPlan?.intervalLength > 1 && 's'} so you don’t lose access to your account. No
              refunds or credits except if you don’t see results and follow our money-back policy. To cancel, simply let
              us know by email: {APP_CONSTANTS.EMAIL}
            </Typography>

            <Link target="_blank" href={Routes.PRIVACY} className="link">
              Privacy Policy
            </Link>
            <Link target="_blank" href={Routes.TERMS} className="link">
              Terms of Use
            </Link>
            <Link target="_blank" href={Routes.BILLING_TERMS} className="link">
              Billing Terms
            </Link>
            <Link href={`mailto:${APP_CONSTANTS.EMAIL}`} className="link">
              Contact Support
            </Link>
            <Typography
              sx={{
                fontFamily: 'Sans400',
                fontSize: '16px',
                color: '#717889',
                textAlign: 'center',
                margin: '8px 0',
                maxWidth: '220px',
              }}
            >
              {APP_CONSTANTS.APP_NAME}
              <br />
              {APP_CONSTANTS.ADDRESS}
            </Typography>
            <Link href={`mailto:${APP_CONSTANTS.EMAIL}`} className="link">
              {APP_CONSTANTS.EMAIL}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
