import { useState, ReactNode, createContext, FC } from 'react';

import { DEFAUL_QUIZ_DATA, QuizContextType, QuizData } from './QuizProvider.types';

export const QuizContext = createContext<QuizContextType | null>(null);

export const QuizProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [step, setStep] = useState(1);
  const [quizData, setQuizData] = useState<QuizData>(DEFAUL_QUIZ_DATA);

  // console.log('quizData', quizData);

  const value: QuizContextType = {
    step,
    setStep,
    quizData,
    setQuizData,
  };

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>;
};
