export const APP_CONSTANTS = {
  EMAIL: 'support@mysticinsight.online',
  ADDRESS: '30 N Gould st Ste 4118, Sheridan, WY 82801, USA +13072139072',
  APP_NAME: 'Milky Way Entertainment LLC',
  APP_SHORT_NAME: 'Milky Way',
  WEBSITE_NAME: 'Mystic insights',
} as const;

export const PLANS = [
  {
    name: '1-Month plan',
    trialPeriod: '3-Day Trial',
    trialUnit: 'day',
    trialLenght: 3,
    fullPrice: 10,
    intervalLength: 1,
    intervalUnit: 'month',
  },
  {
    name: '6-Month plan',
    trialPeriod: '3-Day Trial',
    trialUnit: 'day',
    trialLenght: 3,
    fullPrice: 25,
    intervalLength: 6,
    intervalUnit: 'month',
  },
  {
    name: '12-Month plan',
    trialPeriod: '3-Day Trial',
    trialUnit: 'day',
    trialLenght: 3,
    fullPrice: 50,
    intervalLength: 12,
    intervalUnit: 'month',
  },
];
