import React from 'react';
import { Box, Typography } from '@mui/material';
import mob_head from '../../../assets/images/subscription/mob_head.webp';
import mob_bottom from '../../../assets/images/subscription/mob_bottom.webp';
import calendar from '../../../assets/svg/calendar.svg';
import shield from '../../../assets/svg/shield.svg';
import ChoosePlan from './ChoosePlan';

function Header() {
  return (
    <Box
      sx={{
        backgroundColor: '#01091C',
        backgroundImage: `url(${mob_head}), url(${mob_bottom})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'top, bottom',
        paddingBottom: '300px',
        '@media (max-width: 450px)': {
          paddingBottom: '250px',
        },
        '@media (max-width: 400px)': {
          paddingBottom: '220px',
        },
      }}
    >
      <Box
        sx={{
          maxWidth: '460px',
          margin: 'auto',
          padding: '0 20px',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Kalnia500',
            fontSize: '32px',
            color: '#fff',
            paddingTop: '216px',
            textAlign: 'center',
            marginBottom: '24px'
          }}
        >
          Find peace of mind and know yourself better
        </Typography>
        <ChoosePlan></ChoosePlan>
      </Box>
      <Box
        sx={{
          color: '#717889',
          fontFamily: 'Sans400',
          fontSize: '16px',
          display: 'flex',
          margin: '24px 0',
          '& .wrapper': {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            gap: '8px',
            '& div': {
              borderRadius: '50%',
              backgroundColor: '#18182E',
              width: '64px',
              height: '64px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          },
        }}
      >
        <Box className="wrapper" borderRight={'1px solid #18182E'}>
          <Box>
            <img src={calendar} alt="calendar"></img>
          </Box>
          <Typography>
            No commitment.
            <br /> Cancel anytime.
          </Typography>
        </Box>
        <Box className="wrapper">
          <Box>
            <img src={shield} alt="shield"></img>
          </Box>
          <Typography>
            30-Day Money-Back
            <br /> Guarantee
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
