import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Camera } from 'react-camera-pro';

import QuizButton, { ButtonType } from '../../common/QuizButton';

function TakePhoto({ onClick, setWebcamOpen }: { onClick: () => void; setWebcamOpen: (state: boolean) => void }) {
  const [isWebcamAllowed, setWebcamAllowed] = useState(false);
  const camera = useRef(null);

  useEffect(() => {
    const checkCameraAccess = async () => {
      let stream = null;
      const constraints = {
        video: { facingMode: 'environment' },
      };

      try {
        stream = await navigator.mediaDevices.getUserMedia(constraints);
        if (stream.active) {
          setWebcamAllowed(true);
        }
      } catch (err) {
        console.log('Webcam error', err);
      }
    };
    checkCameraAccess();
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
      }}
    >
      <Camera ref={camera} facingMode="environment" errorMessages={{}} />
      {!isWebcamAllowed && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            color: '#717889',
            fontFamily: 'Sans400',
            fontSize: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Please allow access to camera
        </Box>
      )}
      <Box
        sx={{
          position: 'fixed',
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '56px',
            height: '56px',
            borderRadius: '50%',
            border: '4px solid #E1413E',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: isWebcamAllowed ? 'pointer' : 'auto',
            opacity: isWebcamAllowed ? 1 : 0.5,
          }}
          onClick={isWebcamAllowed ? onClick : undefined}
        >
          <Box
            sx={{
              borderRadius: '50%',
              width: '40px',
              height: '40px',
              background: '#fff',
            }}
          ></Box>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Sans400',
            fontSize: '20px',
            color: '#fff',
            margin: '16px 0 24px',
          }}
        >
          We do not collect your biometric data
        </Typography>
        <Box display={'flex'} padding={'0 0 20px'} width={'200px'}>
          <QuizButton
            onClick={() => {
              setWebcamOpen(false);
            }}
            variant={ButtonType.BLACK}
            text="Back"
          ></QuizButton>
        </Box>
      </Box>
    </Box>
  );
}

export default TakePhoto;
