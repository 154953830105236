import React from 'react';
import { QuizProvider } from './providers/QuizProvider';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from './components/Main';
import Quiz from './components/Quiz';
import Subscription from './components/Subscription';
import Terms from './components/Docs/Terms';
import BillingTerms from './components/Docs/BillingTerms';
import Privacy from './components/Docs/Privacy';

export enum Routes {
  MAIN = '/',
  QUIZ = '/quiz',
  SUBSCRIPTION = '/subscription',
  PRIVACY = '/privacy',
  TERMS = '/terms',
  BILLING_TERMS = '/billing-terms',
}

const router = createBrowserRouter([
  {
    path: '*',
    element: <Main></Main>,
  },
  {
    path: Routes.QUIZ,
    element: <Quiz></Quiz>,
  },
  {
    path: Routes.SUBSCRIPTION,
    element: <Subscription></Subscription>,
  },
  {
    path: Routes.PRIVACY,
    element: <Privacy></Privacy>,
  },
  {
    path: Routes.TERMS,
    element: <Terms></Terms>,
  },
  {
    path: Routes.BILLING_TERMS,
    element: <BillingTerms></BillingTerms>,
  },
]);

function App() {
  return (
    <QuizProvider>
      <RouterProvider router={router} />
    </QuizProvider>
  );
}

export default App;
