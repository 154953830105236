import React from 'react';
import { Box, Checkbox, Link, Modal, Typography } from '@mui/material';
import checkbox from '../../../assets/svg/checkbox.svg';
import QuizButton, { ButtonType } from '../../common/QuizButton';
import { useQuizState } from '../../../providers/QuizProvider.hooks';
import { Routes } from '../../../App';
import { PLANS } from '../../../utils/constants';

function ChoosePlan() {
  const { setQuizData, quizData } = useQuizState();
  const [isModalOpen, setModalOpen] = React.useState(false);

  return (
    <>
      <Box>
        <Box
          sx={{
            height: '275px',
            border: '1px solid #30304B',
            borderRadius: '20px',
            display: 'flex',
            flexDirection: 'column',
            padding: '8px',
            gap: '8px',
          }}
        >
          {PLANS.map((plan) => (
            <Box
              key={plan.name}
              sx={{
                height: '81px',
                backgroundColor: '#18182E',
                borderRadius: '16px',
                padding: '8px',
                display: 'flex',
                cursor: 'pointer',
                border: plan.name === quizData.selectedPlan ? '2px solid #E1413E' : '2px solid #18182E',
              }}
              onClick={() => setQuizData((prev) => ({ ...prev, selectedPlan: plan.name }))}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  justifyContent: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  marginLeft: '16px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Kalnia500',
                    fontSize: '20px',
                    color: '#FFF',
                  }}
                >
                  {plan.name}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Sans400',
                    fontSize: '14px',
                    color: '#717889',
                  }}
                >
                  ${plan.fullPrice}
                </Typography>
              </Box>
              <Box
                sx={{
                  backgroundColor: plan.name === quizData.selectedPlan ? '#E1413E' : '#30304B',
                  borderRadius: '8px',
                  width: '117px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Kalnia500',
                    fontSize: '20px',
                    color: '#FFF',
                  }}
                >
                  $1
                </Typography>
                <Typography
                  sx={{
                    fontFamily: 'Sans400',
                    fontSize: '14px',
                    color: '#FFF',
                    opacity: 0.7,
                  }}
                >
                  per 3-day trial
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
        <Box sx={{ display: 'flex', gap: '16px', margin: '16px 0' }}>
          <Box
            sx={{
              maxWidth: '36px',
              width: '100%',
              height: '36px',
              borderRadius: '12px',
              border: '1px solid #30304B',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Checkbox
              checked={quizData.isAgreed}
              value={quizData.isAgreed}
              onClick={() => setQuizData((prev) => ({ ...prev, isAgreed: !prev.isAgreed }))}
              checkedIcon={<img src={checkbox} alt="checkbox"></img>}
              sx={{
                color: '#E1413E',
              }}
            ></Checkbox>
          </Box>
          <Typography
            sx={{
              color: '#717889',
              fontFamily: 'Sans400',
              fontSize: '14px',
              '& .link': {
                color: '#fff',
                textDecoration: 'underline',
                transition: 'all 0.3s ease',
                whiteSpace: 'nowrap',
                '&:hover': {
                  opacity: 0.8,
                },
              },
            }}
          >
            I got acquainted with the{' '}
            <Link href={Routes.TERMS} className="link" target="_blank">
              terms of use
            </Link>{' '}
            and the rules of auto-renewal subscription
          </Typography>
        </Box>
        <QuizButton
          disabled={!quizData.isAgreed}
          onClick={() => setModalOpen(true)}
          variant={ButtonType.RED}
          text="Try 3-day trial"
        ></QuizButton>
      </Box>

      <Modal
        open={isModalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
          }}
        >
          <Box
            sx={{
              maxWidth: '460px',
              margin: 'auto',
              padding: '0 20px',
            }}
          >
            <Box
              sx={{
                backgroundColor: '#30304B',
                padding: '20px',
                borderRadius: '20px',
                textAlign: 'center',
              }}
            >
              <Typography
                sx={{
                  color: '#fff',
                  fontFamily: 'Kalnia500',
                  fontSize: '32px',
                  marginBottom: '16px',
                }}
              >
                Something went wrong
              </Typography>
              <Typography
                sx={{
                  color: '#fff',
                  fontFamily: 'Sans400',
                  fontSize: '20px',
                  marginBottom: '32px',
                }}
              >
                Unfortunately, it is currently impossible to pay for the plan you have chosen, we sincerely apologize.
                Come back later
              </Typography>
              <QuizButton
                onClick={() => {
                  setModalOpen(false);
                }}
                variant={ButtonType.RED}
                text="Got it"
              ></QuizButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
}

export default ChoosePlan;
